.c-counter {
  padding: 1rem;

  background-color: $light-c;

  @include mappy-query(tablet) {
    margin: 0 1rem;
    padding: 0 1.5rem;
  }
}

.c-counter__number {
  margin-bottom: vr(0.5);

  font-size: 2.8rem;
  font-weight: 500;

  line-height: 1;

  text-align: center;

  color: $secondary-c--lighter;
}

.c-counter__name {
  font-size: 1.2rem;
  font-weight: 700;

  text-align: center;
  text-transform: uppercase;

  color: $text-c--lighter;
}

.c-counter__text {
  line-height: 2;
}
