.c-benefits {
  list-style: none;
  padding: 0;

  @include mappy-query(tablet-bigger) {
    max-width: 43.625rem;

    margin: 0 auto;
  }
}

.c-benefits__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: vr(.75);

  @include mappy-query(tablet-bigger) {
    align-items: center;
  }
}

.c-benefits__icon {
  width: 40px;
  margin-right: 1rem;
  margin-top: 3px;
}

.c-benefits__desc {
  font-size: $font-s--smaller;
}