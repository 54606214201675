.l-members {
  @include mappy-query(tablet) {
    display: flex;
  }
}

.l-members__info {
  @include mappy-query(tablet) {
    max-width: 50%;

    flex: 1 1 50%;

    padding-right: 1rem;
  }

  @include mappy-query(screen) {
    padding-right: 2rem;
  }
}

.l-members__images {
  margin-top: vr(1.5);

  @include mappy-query(mobile-bigger) {
    display: flex;

  }

  @include mappy-query(tablet) {
    justify-content: center;
    align-items: center;

    max-width: 50%;

    flex: 1 1 50%;

    margin-top: 0;
  }
}

.c-member {
  position: relative;

  width: $member-w;
  height: $member-h;

  margin: 0 auto 1rem;

  @include mappy-query(mobile-bigger) {
  }

  @include mappy-query(tablet) {
  }

  @include mappy-query(screen) {
    float: left;

    margin-left: 0;
    margin-right: 2rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.c-member__img,
.c-member__name {
  width: 100%;
  height: 100%;

  overflow: hidden;

  border-radius: 50%;
}

.c-member__img {
  position: relative;
  z-index: 2;

  margin: 0 auto;

  opacity: 1;

  transition: all .24s linear;
}

.c-member__name {
  display: flex;

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  background-color: $primary-c;

  font-weight: 700;

  color: $light-c;
}

.c-member:hover .c-member__img,
.c-member:focus .c-member__img {
  opacity: 0;
}
