.l-results {
  @include mappy-query(tablet-bigger) {
    display: flex;
    flex-wrap: wrap;
  }
}

.l-result {
  margin-bottom: vr(1);

  @include mappy-query(tablet-bigger) {
    flex: 0 0 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.c-result {
  position: relative;

  height: 100%;

  padding: 1.25rem 1.875rem;

  background-color: $light-c;
  box-shadow: 3px 3px 8px rgba($dark-c, .06);

  &:before {
    content: "";
    
    position: absolute;
    left: 0;
    top: 0;

    display: block;
    width: 0;
    height: 0;

    border-top: 37px solid $primary-c--darker;
    border-right: 37px solid transparent;
  }

  &:after {
    content: "+";
    
    position: absolute;
    left: 4px;
    top: 1px;

    line-height: 1;
    font-size: $font-s--biggest;
    font-weight: 500;
    color: $light-c;
  }
}

.c-result__heading {
  margin-top: 0;
  margin-bottom: .5rem;
}

.c-result__text {
  margin: 0;

  line-height: 1.5;
}
