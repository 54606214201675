.c-countries__list {
  display: flex;
  flex-wrap: wrap;

  list-style-position: inside;

  padding-left: 0;
}

.c-countries__item {
  flex: 0 0 100%;

  @extend .h5;
  font-family: $font-family--headlines;

  @include mappy-query(tablet) {
    max-width: 50%;
    
    flex: 0 0 50%;
  }
}
