.c-logo {
  @include mappy-query(tablet) {
    display: block;
    height: $header-h;
  }
}

.c-logo__link {
  display: block;
}

.c-logo__img {
  display: block;

  height: 36px;

  @include mappy-query(tablet) {
    height: $logo-h;
    margin-top: calc((#{$header-h} - #{$logo-h}) / 2);
  }
}

.c-logo__img--white * {
  fill: $light-c !important;
  opacity: .98;
}

.l-header .c-logo__img {
  display: none;
}

.l-header .c-logo__img--white {
  display: block;
}

.l-header.is-scrolled .c-logo__img,
.l-header.is-open .c-logo__img {
  display: block;
}

.l-header.is-scrolled .c-logo__img--white,
.l-header.is-open .c-logo__img--white {
  display: none;
}
