.c-positions {
  width: 100%;

  list-style-type: none;
  padding: 0;

  background-color: $light-c;
  box-shadow: 0 0 26px 1px rgba(0,0,0, .08);

  @include mappy-query(tablet) {
    max-width: $positions-max-w;
    margin: 0 auto;
  }
}

.c-positions__item {
  margin-bottom: 0;
  border-bottom: 1px solid $neutral-c;

  transition: background-color .18s linear;

  &:last-child {
    border-bottom: 0 none;
  }
}

.c-positions__link {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding-right: 1.5rem;
  padding-left: 1.5rem;

  text-decoration: none;

  min-height: 68px;

  @include mappy-query(tablet) {
    height: 68px;
  }
}

.c-positions__info {
  flex: 1 1 auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-positions__icon {
  display: none;
  
  width: 32px;
  height: 32px;

  margin-right: 1rem;

  fill: $primary-c--dark;
  
  @include mappy-query(tablet) {
    display: flex;
  }
}

.c-positions__name {
  color: $text-c;
  font-size: $font-s--bigger;
  font-family: $font-family--text;
}

.c-positions__name--contrast {
  color: $primary-c;
}

.c-positions__action {
  flex: 0 0 60px;
  justify-content: flex-end;

  @include mappy-query(tablet) {
    flex: 0 0 80px;
  }
}

.c-positions__btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  color: $secondary-c--lighter;
  font-family: $font-family--headlines;
  font-weight: 500;
  font-size: $font-s--smaller;
  text-transform: uppercase;
}

.c-positions__btn__arrow {
  display: none;
  margin-left: 4px;
  transition: transform .18s linear;

  fill: $secondary-c--lighter;

  @include mappy-query(tablet) {
    display: flex;
  }
}

.c-positions__item:hover,
.c-positions__item:focus,
.c-positions__item:active {
  background-color: $primary-c--darker;

  .c-positions__icon,
  .c-positions__icon *,
  .c-positions__icon svg {
    fill: $light-c !important;
  }

  .c-positions__name,
  .c-positions__name--contrast,
  .c-positions__btn {
    color: $light-c;
  }

  .c-positions__btn__arrow {
    transform: translateX(8px);
    fill: $light-c;
  }
}
