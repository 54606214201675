.l-features {
  margin-left: -3rem
}

.l-features-row {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: vr(3);
}

.l-features-row--reverse {
  flex-direction: row-reverse;
}

.l-feature {
  flex: 0 0 100%;

  padding-left: 3rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include mappy-query(tablet) {
    max-width: 50%;
    
    flex: 1 1 50%;
  }
}

.l-feature__img {
  @extend .responsive-img;

  max-height: 320px;

  margin-right: auto;
  margin-left: auto;
}

.l-feature__img--tablet {
  max-height: 300px;
  margin: 0 auto;
}

.c-feature {
  display: flex;

  align-items: center;

  height: 100%;

  margin-bottom: vr(1);

  line-height: 2;
}

.c-feature__inner {
  padding: vr(1) 1rem 0;

  @include mappy-query(tablet) {
    padding-top: 0;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }
}

.c-feature__icon {
  display: block;

  width: 50px;
  height: 50px;

  margin: 0 auto vr(.5) auto;

  fill: $secondary-c;
}

.c-feature__header {
  margin-bottom: vr(.50);
}

.c-feature__heading {
  @include typi('h2-font-map');

  margin-bottom: vr(.5);
}
