.c-grid {
  display: flex;
  flex-flow: wrap;

  margin-bottom: vr(1);

  @include mappy-query(screen) {}
}

.c-grid__row {
  display: flex;
  flex-wrap: wrap;

  border-bottom: 1px solid $neutral-c;

  &:last-of-type {
    border-bottom: 0 none;
  }
}

.c-grid__cell {
  display: flex;

  align-items: center;

  max-width: 50%;

  flex: 0 0 50%;

  padding: 1.5rem 1rem;

  border-left: 1px solid $neutral-c;

  border: 0 none !important;

  &:nth-of-type(odd) {
    border-left: 0 none;
  }

  &:nth-of-type(-n + 2) {
    border-bottom: 1px solid $neutral-c;
  }

  @include mappy-query(tablet) {
    &:nth-of-type(odd) {
      border-left: 1px solid $neutral-c;
    }

    &:first-of-type {
      border-left: 0 none;
    }

    &:nth-of-type(-n + 2) {
      border-bottom: 0 none;
    }

    &:nth-of-type(-n + 1) {
      border-left: 0 none;
    }
  }

  @include mappy-query(tablet) {
    max-width: 25%;

    flex: 0 0 25%;
  }
}

.c-grid__cell--third {
  padding: 1rem;

  &:nth-of-type(odd) {
    border-left: 0 none;
  }

  border-bottom: 1px solid $neutral-c;

  &:nth-of-type(n + 7) {
    border-bottom: 0;
  }

  &:nth-of-type(9) {
    display: none;
  }

  @include mappy-query(tablet) {
    max-width: 33.333%;

    flex: 0 0 33.333%;

    &:nth-of-type(odd) {
      border-left: 1px solid $neutral-c;
    }

    &:nth-of-type(-n + 3) {
      border-top: 0;
    }

    &:nth-of-type(-n + 2) {
      border-bottom: 1px solid $neutral-c;
    }

    &:nth-of-type(1),
    &:nth-of-type(4),
    &:nth-of-type(7) {
      border-left: 0 none;
    }

    &:nth-of-type(9) {
      display: flex;
    }
  }
}

.c-grid__logo {
  display: block;

  max-width: 100%;

  height: auto;
  max-height: 60px;

  margin: 0 auto;

  @include mappy-query(tablet) {
    max-width: 100%;
  }

  @include mappy-query(screen) {
    max-width: 120px;
  }
}
