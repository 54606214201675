.l-container {
  padding: vr(1.5) $container-gap vr(1);

  @include mappy-query(tablet) {
  }

  @include mappy-query(screen) {
    max-width: 76.5rem;
    margin: 0 auto;
    padding-bottom: 3rem;
  }

  @include mappy-query(largescreen) {
    margin: 0 auto;
    padding: 3rem 0 3rem;
  }
}

.l-container--narrow {
  @include mappy-query(screen) {
    width: 60%;
    max-width: 50rem;
  }
}

.l-container--narrower {
  @include mappy-query(screen) {
    width: 80%;
    max-width: 60rem;
  }
}

.l-container--narrow {
  max-width: 43.5rem;
}

.l-container--small-padd-bot {
  padding-bottom: vr(1);
}

.l-container--small-padd-top {
  padding-top: vr(1);
}

.l-container--desktop-no-padd-bot {
  @include mappy-query(tablet) {
    padding-bottom: 0;
  }
}

.l-container--no-heading {
  margin-top: 2.5rem;
}

.l-container--no-padd-top {
  padding-top: 0;
}

.l-container--no-padd-bot {
  padding-bottom: 0;
}

.l-container--mobile-no-padd-x {
  padding-right: 0;
  padding-left: 0;

  @include mappy-query(screen) {
    padding-right: $container-gap;
    padding-left: $container-gap;
  }
}

.l-flex {
  @include mappy-query(tablet) {
    display: flex;
  }

  @include mappy-query(screen) {
    flex-wrap: nowrap;
  }
}

.l-flex--wrap {
  flex-wrap: wrap;
}

.l-flex--row-reverse {
  @include mappy-query(tablet) {
    flex-direction: row-reverse;
  }
}

.l-half {
  max-width: 100%;
  flex: 0 0 100%;

  @include mappy-query(tablet) {
    max-width: 50%;

    flex: 1 1 50%;
  }
}

.l-half--map {
  max-width: 100%;

  flex: 0 0 100%;

  @include mappy-query(tablet-landscape) {
    max-width: 50%;

    flex: 1 1 50%;
  }
}

.l-half--gap {
  @include mappy-query(tablet) {
    margin-right: 2rem;
  }
}

.l-half--gap-big {
  @include mappy-query(tablet) {
    margin-right: 2rem;
  }

  @include mappy-query(screen) {
    margin-right: 4rem;
  }
}

.l-third {
  @include mappy-query(tablet) {
    max-width: 33.333333%;

    flex: 1 1 33.333333%;
  }

  @include mappy-query(screen) {
    max-width: 33.333333%;

    flex: 1 1 33.333333%;
  }
}

.l-third--gap {
  @include mappy-query(tablet) {
    margin-right: 3rem;
  }
}

.l-two-thirds {
  @include mappy-query(tablet) {
    max-width: 66.666666%;

    flex: 1 1 66.666666%;
  }


  @include mappy-query(screen) {
    max-width: 66.666666%;

    flex: 1 1 66.666666%;
  }
}

.l-x-center {
  margin-right: auto;
  margin-left: auto;
}

.l-x-space-between {
  justify-content: space-between;
}

.l-justify-end {
  justify-content: flex-end;
}

.l-justify-center {
  justify-content: center;
}

.l-align-center {
  align-items: center;
}

.l-align-end {
  align-items: flex-end;
}

.l-align-self-center {
  align-self: center;
}
