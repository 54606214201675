.l-numbers {
  display: flex;
  flex-wrap: wrap;
}

// Case study version
.l-numbers--vertical {
  flex-flow: column;
  justify-content: space-between;

  height: 100%;

  padding-bottom: 8px;
}

.c-number {
  text-align: center;

  max-width: 50%;

  flex: 0 0 50%;

  margin-bottom: vr(1);

  @include mappy-query(tablet) {
    max-width: 25%;

    flex-basis: 25%;

    margin-bottom: 0;
  }
}

// Case study version
.c-number--full-w {
  display: flex;
  flex-flow: column;
  justify-content: center;

  flex: 1 1 100%;
  max-width: 100%;

  @include mappy-query(tablet) {
    flex: 1 1 100%;
    max-width: 100%;
  }

  @include mappy-query(tablet-bigger) {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.c-number__count {
  @extend .h1;

  font-family: 'ubuntu', Verdana, Tahoma, sans-serif;
  font-size: 2.6rem;
  font-weight: 700;
  color: $primary-c;

  line-height: 1.2;

  @include mappy-query(tablet) {
    font-size: 3rem;
  }

  @include mappy-query(screen) {
    font-size: 3.6rem;
  }
}

.c-number__count--with-picto {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-number__heading {
  margin: 0;
}

.c-number__picto,
.c-number__small {
  font-weight: 700;
}

.c-number__picto {
  font-size: $font-s--biggest;
  text-indent: -12px;
}

.c-number__small {
  position: relative;
  top: -5px;
  display: block;

  font-size: $font-s--smaller;
}
