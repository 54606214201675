.btn {
  display: inline-block;

  position: relative;
  z-index: 1;

  padding: 1rem 1.2rem;

  border: 0 none;

  overflow: hidden;

  font-family: 'ubuntu', 'open-sans', Verdana, Tahoma, sans-serif;
  font-weight: 500;

  text-transform: uppercase;
  text-decoration: none;
  color: $light-c;

  border-radius: 4px;
  box-shadow: 0 0 0 0;
  cursor: pointer;

  @include mappy-query(tablet) {
    padding: 1rem 2.4rem;
  }
}

.btn__content {
  position: relative;
  z-index: 2;
}

.btn-wrapper {
  height: 60px;
  overflow: hidden; 
}

.btn--cta {
  position: relative;

  min-width: 160px;
  height: 60px;

  background-color: darken($secondary-c--lighter, 5%);

  transition: background-color .15s linear;

  &:after {
    content: "";

    position: absolute;
    z-index: 1;
    top: -25%;
    right: -.625rem;

    width: 5.325rem;
    height: 150%;

    background-color: $secondary-c--lighter;

    transform: rotate(-20deg) translateX(1.5rem);

    transition: transform .26s ease-out, background-color .22s linear;
  }
}

.btn--cta:hover,
.btn--cta:focus,
.btn--cta:active,
.btn--cta.active {
  background-color: darken($secondary-c--lighter, 8%);

  &:after {
    background-color: darken($secondary-c--lighter, 3%);

    transform: rotate(-20deg) translateX(0);
  }
}

.btn--cta:disabled {
  background-color: #a2a2a2;

  cursor: not-allowed;

  &:after {
    background-color: #989898;
  }
}

.btn--cta--simple {
  &:after {
    display: none;
  }
}

.btn--meet {
  padding: .485rem 1.8rem;

  background-color: $light-c;
  border: 2px solid $light-c;

  text-decoration: none;

  color: $primary-c--darkest;
}

.btn-chat {
  position: fixed;
  z-index: 999;
  right: 1rem;
  bottom: 1rem;

  width: $map-trigger-w;
  height: $map-trigger-h;

  padding: 0 1rem;

  background-color: $secondary-c;
  border: 0 none;

  transition: transform .16s ease-out;

  box-shadow: 0 0px 10px 2px rgba(00, 00, 00, .12);

  border-radius: 50%;

  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 50%;

    transform: translate(-50%, -50%);

    fill: $light-c;
  }

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    transform: scale(1.14);
  }

  &:focus {
    outline: none;
  }
}
