.c-paralax-img {
  position: relative;

  max-width: 100%;
  min-height: $paralax-img-min-h;

  background-color: $neutral-c--lightest;

  background-image: url(#{$img-path}/hp_bg.jpg);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;

  @include mappy-query(tablet) {
    height: $paralax-img-h--tablet;
  }

  @include mappy-query(screen) {
    height: $paralax-img-h--screen;
    background-attachment: fixed;
  }

  @include mappy-query(largescreen) {
    height: $paralax-img-h--largescreen;
  }
}

.c-paralax-img--small {
  height: 240px;
}

.webp .c-paralax-img {
  background-image: url(#{$img-path}/hp_bg.webp);
}

// CONTACT US PAGE
.c-paralax-img--contact {
  background-image: url(#{$img-path}/join_us.jpg);
}

.webp .c-paralax-img--contact {
  background-image: url(#{$img-path}/join_us.webp);
}

// JOIN US PAGE
.c-paralax-img--join {
  background-position: center bottom;
  background-image: url(#{$img-path}/join.jpg);
}

.webp .c-paralax-img--join {
  background-image: url(#{$img-path}/join.webp);
}

// OUR VALUES PAGE
.c-paralax-img--values {
  background-position: center bottom;
  background-image: url(#{$img-path}/hp_bg-2.jpg);
}

.webp .c-paralax-img--values {
  background-image: url(#{$img-path}/hp_bg-2.webp);
}

// HOW WE WORK PAGE
.c-paralax-img--work {
  background-position: center bottom;
  background-image: url(#{$img-path}/how_we_work.jpg);
}

.webp .c-paralax-img--work {
  background-image: url(#{$img-path}/how_we_work.webp);
}

// CASE STUDIES
.c-paralax-img--case-studies {
  background-position: center center;
  background-image: url(#{$img-path}/case_studies.png);
}

.webp .c-paralax-img--case-studies {
  background-image: url(#{$img-path}/case_studies.webp);
}

.c-paralax-img--spor {
  background-color: $sato-bg-c;
  background-image: none;
}

.webp .c-paralax-img--spor {
  background-image: none;
}

.c-paralax-img--ngi {
  background-color: $ngi-primary-c--darker;
  background-image: none;
}

.webp .c-paralax-img--ngi {
  background-image: none;
}

.c-paralax-img__overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: $dark-c;
  opacity: .68;
}

.c-paralax-img__overlay--more-transparent {
  opacity: .10;
}

.c-paralax-img__container {
  @extend .l-container;
  display: flex;

  align-items: center;
  justify-content: center;

  height: 100%;

  padding-bottom: vr(1.5);

  @include mappy-query(tablet) {
    justify-content: flex-start;
  }
}

.c-paralax-img__claim {
  position: relative;

  width: 100%;

  margin-top: vr(2.5);
  margin-bottom: vr(1.5);

  padding: vr(1) 0;

  border-radius: 5px;

  @include mappy-query(tablet) {
  }

  @include mappy-query(screen) {
    margin-top: 60px;
  }
}

.c-paralax-img__claim.c-paralax-img__claim--subpage {
  @include mappy-query(screen) {
    margin-top: 90px;
  }
}

.c-paralax-img__heading {
  @extend .h1;

  font-weight: 700;
  line-height: 1.4;

  color: $primary-c--lighter;

  text-shadow: 0 0px 8px rgba(00, 00, 00, .48);

  text-align: center;
  text-transform: none;
}

.c-paralax-img__heading--case {
  @extend .h2;
  text-shadow: 0 0 0;
  text-transform: uppercase;
  color: $light-c;
}

.c-paralax-img__button {
  display: none;

  position: absolute;

  bottom: 60px;
  left: 50%;

  transform: translateX(-50%);

  @include mappy-query(tablet) {
    display: block;
  }
}

.c-paralax-img--hp-2 {
  display: none;

  background-image: url(#{$img-path}/hp_bg-2.jpg);
  background-position: center 0;
  background-size: cover;

  @include mappy-query(tablet) {
    display: flex;

    min-height: 240px;
    height: 240px;
  }
}

.webp .c-paralax-img--hp-2 {
  background-image: url(#{$img-path}/hp_bg-2.webp);
}

.c-paralax-img--hp-3 {
  display: none;

  background-image: url(#{$img-path}/hp_bg-3.jpg);
  background-position: center 0;
  background-size: cover;

  @include mappy-query(tablet) {
    display: flex;

    min-height: 240px;
    height: 240px;
  }
}

.webp .c-paralax-img--hp-3 {
  background-image: url(#{$img-path}/hp_bg-3.webp);
}

.c-paralax-img--mix {
  display: none;

  background-image: url(#{$img-path}/mix.jpg);
  background-position: center 0;
  background-size: contain;

  @include mappy-query(tablet) {
    display: flex;

    min-height: 240px;
    height: 240px;
  }
}

.webp .c-paralax-img--mix {
  background-image: url(#{$img-path}/mix.webp);
}
