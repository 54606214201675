.input {
  position: relative;
  display: block;
  height: $input-h;
  @extend %clearfix;
}

.input__field {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  background-color: transparent;
  color: $dark-c;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
}

.input__label {
  float: right;
  padding: 0 1rem;
  width: 100%;
  color: $dark-c;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.input__label-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.9rem 0 0;
  font-size: 90%;
  font-weight: 400;
  color: $dark-c;
  @include mappy-query(tablet) {
    padding-top: 0.65rem;
  }
}

.input__label::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background-color: $light-c;
  transform: scale3d(0.97, 0.85, 1);
  transition: transform 0.3s;
}

.input__label-content {
  transition: transform 0.3s;
}

.input__label-content--required:after {
  content: " *";
  color: red;
}

.input--filled .input__label:before,
.input__field:focus + .input__label:before {
  transform: scale3d(0.99, 0.95, 1);
}

.input--filled .input__label,
.input__field:focus + .input__label {
  cursor: default;
  pointer-events: none;
}

.input--filled .input__label-content,
.input__field:focus + .input__label .input__label-content {
  color: $dark-c;
  transform: translate3d(-.6rem, -2.5rem, 0);
}
