.c-projects-map {
  position: relative;
  display: flex;

  height: 500px;

  overflow: hidden;
}

.c-projects-map--mobile {
  display: block;

  @include mappy-query(tablet) {
    display: none;
  }
}

.c-projects-map__europe {
  
  margin-right: 0;
  margin-left: auto;

  @include mappy-query(tablet) {
    max-width: 60%;
    flex: 0 0 60%;
  }

  @include mappy-query(screen) { 
    max-width: 75%;
    flex: 0 0 75%;
  }

  #cz,
  #sk,
  #uk,
  #uk_2,
  #uk_3,
  #austria,
  #croatia,
  #serbia,
  #serbia_2,
  #bosnia,
  #slovenia,
  #italy,
  #italy_2,
  #italy_3,
  #switzerland,
  #monte_negro {
    fill: $primary-c !important;
  }
}

.c-projects-map__africa {
  max-width: 35%;
  flex: 0 0 35%;
  margin-right: auto;
  margin-left: 5rem;

  #south_africa {
    fill: $primary-c !important;
  }
}

.c-projects-map__sa {
  position: relative;
  z-index: 2;

  width: 100%;

  padding: 2rem;

  border-radius: 50%;
  background-color: $light-c;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, .12);

  transform: rotate(15deg);

  svg #south_africa {
    fill: $primary-c !important;
    cursor: pointer;
  }
}

.c-projects-map__circle {
  position: absolute;
  top: 50px;
  left: 15px;

  width: 30%;
  height: 0;
  padding-bottom: 30%;

  transform: rotate(-15deg);

  &:after {
    content: "";

    position: absolute;
    z-index: -1;
    top: 0;

    display: block;

    width: 2px;

    height: 500px;

    background-color: $neutral-c;
    transform-origin: top;
  }

  &:after {
    right: 50%;
  }

  @include mappy-query(screen) {
    width: 30%;
    padding-bottom: 30%;
  }
}

.c-projects-map__svg {
  display: block;
  height: 100%;

  margin-left: auto;
}

.c-projects-map__info {
  visibility: hidden;
  position: fixed;
  z-index: 10;

  width: auto;
  height: auto;

  padding: .625rem 1rem;

  background-color: $light-c;

  border-radius: 4px;

  box-shadow: 0 0 10px rgba(0, 0, 0, .2 );

  opacity: 0;

  transition: all .20s ease-out;
}

.c-projects-map__info.is-open {
  visibility: visible;
  opacity: 1;
}

.c-projects-map__country {
  @extend .h6;

  margin: 0;
}
