.c-back {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  color: $text-c;
  text-decoration: none;

  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    .c-back__icon {
      transform: rotate(-180deg) translateX(8px);
    }
  }
} 

.c-back__icon {
  margin-right: 6px;
  transform: rotate(-180deg);
  transition: transform .14s linear;

  path {
    fill: $text-c;
  }
}