.c-map {
  background: url(#{$img-path}/map.jpg) no-repeat center center;
  background-size: cover;

  @include mappy-query(tablet) {
    display: block;

    width: 100%;
    height: 320px;

    position: relative;
  }

  @include mappy-query(screen) {
    height: 400px;
  }

  #map {
    width: 100%;
    height: 100%;
  }
}

.webp .c-map {
  background-image: url(#{$img-path}/map.webp);
}
